<template>
  <div
    class="container"
    v-title
    :data-title="$t('i18n.changePassword')"
    id="changePassword"
  >
    <div id="outer-title">{{ $t("i18n.changePassword") }}</div>
    <jl-form :columns="columns" :option="btnOption"></jl-form>
  </div>
</template>

<script>
import { reactive, toRefs, getCurrentInstance } from "vue";
import { useI18n } from "vue-i18n";
import jlForm from "../../components/form";
export default {
  name: "ChangePassword",
  components: {
    jlForm,
  },
  setup(props, { emit }) {
    emit("public_header", true);
    const { proxy } = getCurrentInstance();
    const { t } = useI18n();
    const state = reactive({
      columns: [
        // 需要展示的列
        {
          prop: "oldPassword",
          label: "oldPassword",
          type: "input",
          rules: [{ required: true, message: t("i18n.input") }],
        },
        {
          prop: "newPassword",
          label: "newPassword",
          type: "password",
          rules: [
            { required: true, message: t("i18n.input") },
            {
              pattern: /(?!^\d+$)(?!^[a-zA-Z]+$)(?!^[_#@]+$).{8,}/,
              message: t("i18n.pwdFormat"),
              trigger: "blur",
            },
          ],
        },
        {
          prop: "confirmPassword",
          label: "confirmPassword",
          type: "password",
          rules: [
            { required: true, message: t("i18n.input") },
            {
              pattern: /(?!^\d+$)(?!^[a-zA-Z]+$)(?!^[_#@]+$).{8,}/,
              message: t("i18n.pwdFormat"),
              trigger: "blur",
            },
          ],
        },
      ],
      btnOption: [
        {
          label: "confirmsubmission",
          class: "primary",
          show: true,
          method: (row) => {
            onSubmit(row);
          },
        },
      ],
    });

    const onSubmit = async (row) => {
      if (row.newPassword === row.confirmPassword) {
        await proxy.$api.common.changePassword(row);
        proxy.$defined.tip(t("i18n.edit") + t("i18n.success"), "success");
      } else {
        proxy.$defined.tip(t("i18n.passwordDiffer"));
      }
    };

    return {
      ...toRefs(state),
      onSubmit,
    };
  },
};
</script>

<style lang="scss">
#changePassword {
  .form-button {
    text-align: left;
  }
}
@media screen and (max-width: 768px) {
  #changePassword {
    .form-button {
      text-align: center;
    }
  }
}
</style>
